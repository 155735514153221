import React from "react";

export function Shield() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 22.5C12 22.5 20 18.5 20 12.5V5.5L12 2.5L4 5.5V12.5C4 18.5 12 22.5 12 22.5Z"
        stroke="#FAFAFA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
